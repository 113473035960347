
import { Options, Vue } from 'vue-class-component'
import NaviBar from '@/components/NaviBar.vue'
import service from '@/utils/request'
import { CommentModel, NoteModel, NoteType } from '@/models/message'
import dayjs from 'dayjs'
import { ImagePreview } from 'vant'

@Options({
  components: {
    NaviBar
  }
})
export default class InformationDetail extends Vue {
  noteId = ''
  notes: NoteModel[] = []
  commentList: CommentModel[] = []
  noteType = NoteType
  week = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
  comment = ''
  replyPlaceholder = '请输入评论...'
  replyIndex = -1

  mounted () {
    this.noteId = this.$route.params.noteId as string
    this.loadData()
    this.loadComments()
  }

  getDate (date: string) {
    return dayjs(date).format('YYYY年M月D日')
  }

  loadData () {
    service.get('/mindup/mini/notes/' + this.noteId, {}).then(res => {
      this.notes = []
      this.notes.push(res.data)
    })
  }

  loadComments () {
    console.log('this.noteId == ', this.noteId)
    service.get('/mindup/mini/comments/list', {
      params: {
        commentType: 'NOTE_COMMENT',
        relatedId: this.noteId
      }
    }).then(res => {
      this.commentList = res.data.content
      console.log('this.commentList', this.commentList)
    })
  }

  tapComment (index: number) {
    console.log('index == ', index)
    if (index === -1) {
      this.replyPlaceholder = '请输入评论...'
    } else {
      this.replyPlaceholder = '回复:' + this.commentList[index].createUser.name
    }
    this.replyIndex = index
  }

  publish () {
    if (this.replyIndex === -1) {
      this.publishComment()
    } else {
      this.replyRequest()
    }
  }

  publishComment () {
    service.post('/mindup/mini/comments/publish', {
      content: this.comment,
      relatedId: this.noteId,
      type: 'NOTE_COMMENT'
    }).then(res => {
      console.log(res)
      this.commentList.push(res.data)
      this.comment = ''
    })
  }

  replyRequest () {
    service.post('/mindup/mini/comments/reply', {
      content: this.comment,
      commentId: this.commentList[this.replyIndex].id,
      replyTargetId: this.commentList[this.replyIndex].relatedId,
      type: 'NOTE_COMMENT',
      relatedId: this.noteId
    }).then(res => {
      console.log(res)
      this.comment = ''
      if (!this.commentList[this.replyIndex].commentReply) {
        this.commentList[this.replyIndex].commentReply = []
      }
      this.commentList[this.replyIndex].commentReply.push(res.data)
    })
  }

  tapImage (index: number) {
    const pics = this.notes[0].pics.map(p => p.pic)
    ImagePreview(pics, index)
  }
}
