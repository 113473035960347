import { User } from '@/models/user'
import { Plan, Task } from '@/models/plan'
import { Practice } from '@/models/practice'

export interface Pic {
    pic: string
}

export enum NoteType {
    PRACTICE_TASK_NOTE = 'PRACTICE_TASK_NOTE',
    PLAN_NOTE = 'PLAN_NOTE'
}

export interface Note {
    content: string
    createTime: string
    createdBy: number
    id: number
    pics: Pic[]
    relatedId: number
    type: string
    userId: number
}

export interface Message {
    content: string
    createTime: string
    messageId: number
    messageType: string
    note: Note
    receiverId: number
    relatedId: number
    sender?: User
    isRead: boolean
    id: number
}

export interface NoteModel {
    content: string
    createTime: string
    createUser: User
    id: number
    pics: Pic[]
    plan: Plan
    practice: Practice
    relatedId: number
    task: Task
    type: NoteType
}

export interface CommentModel {
    commentReply: CommentModel[]
    content: string
    createTime: string
    createUser: User
    createdBy: number
    id: number
    lastUpdatedBy: number
    relatedId: number
    type: string
    updateTime: string
}
